export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "STAGE",
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://990r3pd9yg.execute-api.eu-central-1.amazonaws.com/dev",
    APIURL: "https://15qwojae19.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_75wcvrLp2",
    APP_CLIENT_ID: "71fhlp9d53fm7ddccd4ppg2ku6",
    DOMAIN: "https://aa-huntington-stage.auth.eu-central-1.amazoncognito.com",
    IDENTITY_POOL_ID: "eu-central-1:64d34e1b-1ab6-44c1-8db9-50070d4f7575"
  }
};
